/* eslint-disable react/display-name */
import React from 'react';
import { Typography } from '@mui/material';
import { MergeType } from '@mui/icons-material';

const MergeExitsNode = ({ data, isConnectable, draggable }) => {

    const handleDrop = ( event ) => {
        event.preventDefault();
        console.log('dropped on me', event);
    };

    return (
        <div className='flow-merge-node drag-handle'
             data-step='merge'
             data-id={data.stepId}
             // onDrop={handleDrop}
             // draggable={true}
        >
            {/*<Typography variant='caption' component='div' style={{fontWeight: 500, display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 10, marginBottom: 0, marginRight: 2}}>*/}
            {/*    <MergeType fontSize='small' color='primary'/>*/}
            {/*    Drag To A Merge Step*/}
            {/*</Typography>*/}
        </div>
    );
};

export default MergeExitsNode;

// const onDragStart = (event, type, stepId) => {
//     event.dataTransfer.setData('app/flows', type);
//     event.dataTransfer.setData('app/flows/stepId', stepId);
//     event.dataTransfer.effectAllowed = 'move';
//
//     // find the eligible drop targets and denote them
//     console.log('find targets and denote them');
//     const dropTargetNodes = event.view.document.querySelectorAll('.flow-merged-node');
//     dropTargetNodes.forEach((node) => {
//         // console.log('dropTarget', node );
//         node.classList.add('drop-target-available');
//     });
//
// };