import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, List, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    CloseTwoTone,
    CollectionsBookmarkTwoTone,
    EmailTwoTone,
    GroupsTwoTone,
    ImportContactsTwoTone, SettingsApplicationsTwoTone
} from '@mui/icons-material';
import clsx from 'clsx';
import Sidebar from 'components/Sidebar';
import { ConfirmProvider }  from 'material-ui-confirm';
import Logo from 'components/Logo';
import useLocalStore from '../../useLocalStore';
import { ListItemIcon } from '@mui/material';
import leftArrow from 'assets/icons/main/arrow_menu_close.svg';
import rightArrow from 'assets/icons/main/arrow_menu_open.svg';
import SideMenu from 'components/Templates/SideMenu';


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        minWidth: 80,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 79
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
        maxWidth: '1480px',
        margin: 'auto'
    },
    content_flex: {
        flexGrow: 1,
        maxWidth: '1400px',
        margin: 'auto'
    }
}));

const fullscreen = [
    'Dashboard',
    'CreateJourney',
    'EditCampaigns'
];

const sidebarsWithCustomWidth = ['SingleAudience', 'CampaignStatistics', 'CreateJourney', 'Dashboard', 'CampaignsAll', 'Audience'];



const Layout = ({ main, pageName }) => {
    const classes = useStyles();

    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);

    const [showQC, setShowQC] = useState(false);

    const sidebar = getSidebar();

    const toggleQC = () => {
        setShowQC((state) => !state);
    };

    const hideSidebar = async () => {
        resetSidebar();
    };

    return (
        <ConfirmProvider>
        <div className={classes.root}>
            <Drawer
                    variant='permanent'
                    style={{
                        minWidth: 80
                    }}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: showQC,
                        [classes.drawerClose]: !showQC
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: showQC,
                            [classes.drawerClose]: !showQC
                        })
                    }}
                    >
                <div className='layout'>
                    <Logo open={showQC} />
                    <div onClick={toggleQC} className='layout--arrows'>
                        <ListItemIcon className='layout--arrows-list'>
                            {showQC ? (
                                <img src={leftArrow} alt='left' />
                            ) : (
                                <img src={rightArrow} alt='right' />
                            )}
                        </ListItemIcon>
                    </div>
                    <SideMenu isCollapsed={!showQC} open={true} active={pageName} />
                </div>
            </Drawer>
            <main className={`${fullscreen.includes(main.props.subName) ? classes.content_flex : classes.content} ${showQC && 'drawer_open'}`}>
                <Suspense fallback={null}>{main}</Suspense>
            </main>
            <Drawer
                anchor='right'
                open={sidebar?.open ?? false}
                onClose={hideSidebar}
                disableEnforceFocus
                    style={{zIndex: 1350}}
            >
                {/*<Box className={`${sidebarsWithCustomWidth.includes(main.props.subName) && 'context__custom-width'} context`}>*/}
                <Box className={'context__custom-width context'}>
                    <IconButton size='small' variant='contained' onClick={hideSidebar} className='context-close'>
                        <CloseTwoTone />
                    </IconButton>
                    <Sidebar page={pageName} hideSidebar={hideSidebar} />
                </Box>
            </Drawer>
        </div>
        </ConfirmProvider>
    );
};

export default Layout;
