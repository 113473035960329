/* eslint-disable react/display-name */
import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Handle } from 'reactflow';
import { Typography, Tooltip } from '@mui/material';
import { useJourney } from 'api/Journeys/journey_queries';
import { FlagRounded } from '@mui/icons-material';
import useLocalStore from '../../../../useLocalStore';

const HANDLE_STYLE = {
    background: 'transparent',
    border: 0
};

const ExitNode = React.memo(({ data }) => {
    const { id } = useParams();

    const { isDisabled, stepId } = data;
    const [ hasTrigger, setHasTrigger ] = useState(false);
    const [ mergeSidebarOpen, setMergeSidebarOpen ] = useState(false);
    const { isFetching, data: journey } = useJourney(id);

    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);

    const handleSidebar = () => {
        const payload = {
            open: true,
            name: 'JourneyDropout',
            params: {
                flowId: id
            }
        };
        setSidebar(payload);
    };

    const onClickOpenMergeSidebar = () => {
        const payload = {
            open: true,
            name: 'JourneyMergeExit',
            params: {
                flowId: id,
                exitStepId: stepId,
                exitStepData: data,
            }
        };
        setSidebar(payload);
    };

    React.useEffect(() => {
        const isTriggerSet = journey && journey?.status === 'draft' && !(journey?.dropout_filter_draft || journey?.dropout_filter);
        setHasTrigger(isTriggerSet);
    }, [journey]);

    const unsetTriggerClass = hasTrigger ? 'highlight' : '';

    return (
        <div style={{width: 250, margin: 'auto', cursor: 'pointer'}} >
            {/*<Tooltip title='Set Exit Trigger' open={Boolean(hasTrigger)} PopperProps={{ disablePortal: true }} placement='right'>*/}
            {/*    <div className={!isDisabled ? `flow-exit-node ${unsetTriggerClass}` : `flow-exit-node ${unsetTriggerClass} disabled`} onClick={handleSidebar}>*/}
            {/*        <Typography variant='caption' component='div' style={{fontWeight: 500, display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 10}}>*/}
            {/*            <FlagRounded fontSize='small' color='primary' />*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*</Tooltip>*/}

            {/*<Tooltip title='Merge Exit' open={Boolean(!mergeSidebarOpen)} PopperProps={{disablePortal: true}}*/}
            {/*         placement='right' onClick={onClickOpenMergeSidebar}>*/}
                <div
                    className={!isDisabled ? `flow-exit-node ${unsetTriggerClass}` : `flow-exit-node ${unsetTriggerClass} disabled`}
                    onClick={onClickOpenMergeSidebar}>
                    <Typography variant='caption' component='div' style={{
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: 10
                    }}>
                        Exit
                    </Typography>
                </div>
            {/*</Tooltip>*/}


        </div>
    );
});

export default ExitNode;
