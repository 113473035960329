import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';

const FlowActionTriggerProfileEventForm = ({ onSubmit, flowId, triggerType, flowTravelActionType }) => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const submitForm = (data) => {

        const payload = {
            [triggerType]: { type: 'profile_event', ...data },
            trigger_type: triggerType,
            flow_travel_action_type: flowTravelActionType
        };
        onSubmit(payload);
    };

    return (
        <Card sx={{ maxWidth: 400, margin: 'auto' }}>
            <CardHeader title='New Action Trigger' />
            <CardContent>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Controller
                        name='profile_event_type'
                        control={control}
                        rules={{ required: 'A profile event type is required' }}
                        render={({ field }) => (
                            <FormControl fullWidth error={!!errors.profile_event_type} sx={{ mb: 2 }}>
                                <InputLabel id='event-query-label'>Profile Event</InputLabel>
                                <Select
                                    labelId='event-query-label'
                                    label='Profile Event'
                                    {...field}
                                >
                                    <MenuItem value='checkout_start'>Checkout Start</MenuItem>
                                    <MenuItem value='convert'>Convert</MenuItem>
                                </Select>
                                {errors.profile_event_type && (
                                    <FormHelperText>{errors.profile_event_type.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <CardActions sx={{ justifyContent: 'flex-end', padding: 0 }}>
                        <Button type='submit' variant='contained' color='primary'>
                            Create Trigger
                        </Button>
                    </CardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default FlowActionTriggerProfileEventForm;