import * as http from 'util/elemental_axios_client';

export const setCreateJourney = async ({ payload }) => {
    return await http.post('/flows', payload);
};

export const unsetDraftChanges = async ({ id }) => {
    return await http.post(`/flows/${id}/revert`);
};



export const setAppendStep = async ({ id, stepId, payload, target }) => {
    return await http.put(`/flows/${id}/insert_after/${stepId}`, payload);
};

export const setMergeExits = async ({ id, stepId, exitId }) => {
    return await http.put(`/flows/${id}/${stepId}/merge/${exitId}`);
};

export const setStepLabel = async ({ id, stepId, payload }) => {
    return await http.put(`/flows/${id}/steps/common/${stepId}/label`, payload);
};
