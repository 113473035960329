/* eslint-disable react/display-name */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import StepHeader from '../StepHeader';
import StepBody from '../StepBody';
import { useJourneyStepStats, useJourneyStep } from 'api/Journeys/journey_queries';
import useLocalStore from '../../../../useLocalStore';

const HANDLE_STYLE = {
    height: 13,
    width: 13,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px',
    left: '50%'
};

const EmailReportNode = ({ data, status, id, stepId, handleSidebar }) => {
    const { data: stepStats } = useJourneyStepStats(id, stepId);

    const statsReport =
        <Box display='flex' justifyContent='center' className='reportNode' style={{marginInline: 'auto', textAlign: 'center'}}>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.conversions?.orders} / ${stepStats?.conversions.revenue}
                <small>Orders Placed</small>
            </Typography>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.message?.opens_percentage}%
                <small>Open Rate</small>
            </Typography>
            <Typography component='div' variant='caption' style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                {stepStats?.message?.clicks_percentage}%
                <small>Click Rate</small>
            </Typography>
            {/* <Box className='user-stats' p={0}>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Waiting: {stepStats?.wait?.waiting}
                </Typography>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Skipped: {stepStats?.bypass_filter?.skipped}
                </Typography>
                <Typography component='div' variant='caption' style={{width:'100%', display: 'inline-flex'}}>
                    Journeyed: {stepStats?.step?.journeyed}
                </Typography>
            </Box> */}
        </Box>;

    return (
        <>
            <StepHeader step={data} handleSidebar={handleSidebar}/>
            <StepBody status={data} stats={statsReport} handleSidebar={handleSidebar}>
                <div className='flow-node__body' onClick={handleSidebar}>
                    <Typography variant='body2' style={{fontSize: 11}}>
                        {data?.message?.preview_text.substr(0,100)}
                    </Typography>
                    {statsReport}
                </div>
            </StepBody>
        </>
    );
};


const EmailNode = React.memo(({ data, isConnectable, history }) => {
    const { id } = useParams();
    const { isDisabled, stepId, type, status } = data;

    const { data: step } = useJourneyStep(id, stepId);
    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);

    const handleSidebar = (event) => {
        if(event.target.id !== 'campaign-subject') {
            const payload = {
                open: true,
                name: 'Journeys',
                params: {
                    stepId: data.stepId
                }
            };

            setSidebar(payload);
        }
    };

    return (
        step ?
            <div className={!isDisabled ? 'flow-message-node' : 'flow-message-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />
                {/*<EmailReportNode data={step} status={status} id={id} stepId={stepId} handleSidebar={handleSidebar}/>*/}

                <StepHeader step={step} handleSidebar={handleSidebar}/>
                {step?.can_activate &&
                    <StepBody status={step} handleSidebar={handleSidebar}>
                        <div className='flow-node__body' onClick={handleSidebar}>
                            <Typography variant='body2' style={{fontSize: 11}}>
                                {step?.message?.preview_text?.substr(0,100)}
                            </Typography>
                        </div>
                    </StepBody>
                }

                <Handle
                    type='source'
                    position={Position.Bottom}
                    style={HANDLE_STYLE}
                    isConnectable={isConnectable}
                />
            </div>
            :
            <></>
    );
});

export default withRouter(EmailNode);
