import React, {Fragment} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Box, List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import {
    AccountTreeTwoTone,
    CollectionsBookmarkTwoTone,
    EmailTwoTone,
    GroupsTwoTone,
    MeetingRoomTwoTone,
    SettingsApplicationsTwoTone
} from '@mui/icons-material';
import {useAuth} from 'contexts/AuthContext';
import {ImportContactsTwoTone} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import { Sidebar as ReactProSidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';

// const menuLinks =

const SideMenu = ( {isCollapsed} ) => {

    const history = useHistory();
    const links = [
        [
            // { url: '/dashboard', label: 'Delivery Statistics', icon: <AnalyticsTwoTone /> },
            // { url: '/batches', label: 'Batches', icon: <DynamicFeedTwoTone /> },
            // { url: '/import-sessions', label: 'Imports', icon: <ImportContactsTwoTone /> },
            // { url: '/analytics', label: 'Analytics', icon: <Analytics /> },
        ],
        []
    ];

    const { logoutAction, currentKratosSession, organizations } = useAuth();

    const handleListItemClick = (url) => {
        history.push(url);
    };

    const handleLogout = async () => {
        console.log('logout with auth.logoutAction');
        await logoutAction(history);
    };

    let userEmail = currentKratosSession?.identity?.traits?.email;
    let orgName = organizations[0].name;

    const { pathname } = useLocation();

    return (
        <>
        <Box display='flex' flexDirection='column' justifyContent='space-between' flex='0 1 100%' style={{padding: 0, height: '100%'}}>
            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
            <ReactProSidebar collapsed={isCollapsed} style={{ color: '#061942'}}>
                <Menu>
                    <MenuItem label={'Campaigns'} icon={<EmailTwoTone />}
                              component={<Link to='/campaigns' />} >Campaigns</MenuItem>
                    <MenuItem  label={'Journeys'} icon={<AccountTreeTwoTone />} component={<Link to='/journeys' />} >Journeys</MenuItem>
                    <SubMenu label={'Profiles'} icon={<GroupsTwoTone />} >
                        <MenuItem component={<Link to='/audiences' />} >Audiences</MenuItem>
                        <MenuItem component={<Link to='/import-sessions' />} >Import</MenuItem>
                    </SubMenu>
                    <SubMenu label={'Library'} icon={<CollectionsBookmarkTwoTone />}>
                        <MenuItem component={<Link to='/library' />} >Templates</MenuItem>
                    </SubMenu>
                    <SubMenu label={'Setup'} icon={<SettingsApplicationsTwoTone />}>
                        <MenuItem component={<Link to='/settings' />} >Settings</MenuItem>
                        <MenuItem component={<Link to='/email-sender-profiles' />} >Email Sender</MenuItem>
                        <MenuItem component={<Link to='/email-sending-domains' />} >Sending Domain</MenuItem>
                    </SubMenu>
                    <SubMenu label={'Delivery'}>
                        <MenuItem component={<Link to='/vmta-pools' />} >Send Pools</MenuItem>
                    </SubMenu>
                    <SubMenu label={'Admin'}>
                        <MenuItem component={<Link to='/admin/suppression-lists/optizmo' />} >Optizmo</MenuItem>
                        <MenuItem component={<Link to='/admin/campaigns/current' />} >Upcoming/Active Campaigns</MenuItem>
                    </SubMenu>
                </Menu>
            </ReactProSidebar>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                <List style={{padding: 0}}>
                    <Tooltip title='Logout' placement='right'>
                        <List>
                            <ListItem className='side_menu--item' >
                                <ListItemText primary={userEmail} />
                            </ListItem>
                            <ListItem className='side_menu--item' >
                                <ListItemText primary={orgName} />
                            </ListItem>
                        </List>
                    </Tooltip>
                </List>
            </Box>

            <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                <List style={{padding: 0}}>
                    <Tooltip title='Logout' placement='right'>
                        <ListItem className='side_menu--item' button selected={'/logout' === `/${pathname.split('/')[1].split('-')[0]}`} onClick={handleLogout}>
                            <ListItemIcon className='side_menu--icons'><MeetingRoomTwoTone /></ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItem>
                    </Tooltip>
                </List>
            </Box>
        </Box>
        </>
    );
};

export default SideMenu;
