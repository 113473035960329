import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Button, MenuItem, Select, Typography} from '@mui/material';
import {FlagRounded} from '@mui/icons-material';
import {useJourney} from 'api/Journeys/journey_queries';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import {makeStyles} from '@mui/styles';
import MutationLoadingButton from 'components/MutationLoadingButton';
import {enqueueSnackbar} from 'notistack';
import useLocalStore from '../../../../useLocalStore';




const SidebarMergeExit = ( { flowId, exitStepId, exitStepData } ) => {

    const queryClient = useQueryClient();

    const [isEditing, setIsEditing] = useState(false);
    const { data: journey, isSuccess } = useJourney(flowId);
    const [selectedMergeStepId,setSelectedMergeStepId] = useState(0);

    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);

    console.log('exitStepData', exitStepData );

    const filterValidMergeDropTargets = (adjacencyList) => {
        // console.log('adjacencyList', adjacencyList);

        let allowedTargetSteps = [];
        adjacencyList.forEach( (value) => {
            let step = value.step;
            if ( step.type == 'merge' ){
                allowedTargetSteps.push(step);
            }
        });
        // console.log('allowedTargetSteps',allowedTargetSteps);
        return allowedTargetSteps;
    };

    // get all the merge steps
    const {data: mergeSteps} = useQuery({
        queryKey: ['adjacencyList', journey?.id],
        queryFn: async () => {
            return http.get(`/flows/${journey.id}/draft/adjacency_list`);
        },
        select: filterValidMergeDropTargets,
        enabled: !!journey?.id
    });

    const mutationMergeExit = useMutation({
        mutationFn: (mergeStepId) => {
            return http.put(`/flows/${flowId}/${mergeStepId}/merge/${exitStepId}`,{});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['adjacencyList', flowId ]} );
            enqueueSnackbar('Exit Merged');
            resetSidebar();
        }
    });

    const onMerge = () => {
        mutationMergeExit.mutate(selectedMergeStepId);
    };

    const handleClose = () => {

    };

    if(!isSuccess){
        return <div>Loading or error</div>;
    }

    const showCancelButton = true;

    // TODO: selected merge step needs to be an exit

    console.log('mergeSteps', mergeSteps);

    const mergeStepOptions = mergeSteps && mergeSteps.map( step =>
    { console.log('step',step); return <MenuItem key={step.id} value={step.id}>{step.label}</MenuItem>; });

    console.log('mergeStepOptions', mergeStepOptions );

    return (
        <div className='flow-sidebar' style={{width: '15vw'}}>
            <div className='flow-sidebar__header'>
                <Typography component='div' variant='body1' className='flow-sidebar_title no-edit'>
                    <FlagRounded />
                    Merge Into Step:
                </Typography>
            </div>
            {journey.status === 'draft' &&
                <>
                    <Typography component='div' variant='subtitle1' className='flow-sidebar_subtitle' m={1}>
                        Merge [current step info] onto:
                    </Typography>
                    <Box className='flow-sidebar__body'>
                        <div style={{padding: '0px 20px', height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flexGrow: 1}}>
                            <Box
                                className='flow_query_filter create_audience'>

                                <Select
                                    MenuProps={{style: {zIndex: 35001}}}
                                    id='merge-destination'
                                    value={selectedMergeStepId}
                                    onChange={(e) => setSelectedMergeStepId(e.target.value) }
                                    size='small'
                                >
                                    <MenuItem key={0} value={0} >-- select a merge destination step -- </MenuItem>
                                    {mergeStepOptions}
                                </Select>
                            </Box>
                            <Box display='flex' justifyContent='space-between' sx={{mx: 1, my: 1}}>
                                {showCancelButton &&
                                    <Button onClick={handleClose} className='new_primary_button'
                                            style={{marginRight: 'auto'}}>
                                        Cancel
                                    </Button>
                                }
                                <MutationLoadingButton
                                        mutation={mutationMergeExit}
                                        onClick={onMerge}
                                        className='new_primary_button'
                                        type= 'submit'
                                        style={{marginLeft: 'auto'}}
                                    >
                                    Merge
                                </MutationLoadingButton>
                            </Box>
                        </div>
                    </Box>
                </>
            }
        </div>
    );
};

export default SidebarMergeExit;
